<template>
  <div class="w-sm-100 w-xs-100">
    <!--Header-->
    <!--    <vs-row vs-w="12"-->
    <!--            style="border-radius: 10px;border:1px solid black;padding:10px;background-color: #ffffff;color:#000000">-->
    <!--      <vs-col vs-type="flex" vs-align="center" vs-w="6">-->
    <!--        Welcome to TransCrypt !-->
    <!--      </vs-col>-->
    <!--      <vs-col vs-type="flex" vs-justify="flex-end" vs-w="6">-->
    <!--        <div style="display:flex;flex-direction: column">-->
    <!--          <span>Your crypto value</span>-->
    <!--          <span>usd 100</span>-->
    <!--        </div>-->
    <!--      </vs-col>-->
    <!--    </vs-row>-->
    <div class="lg-trading-content">
      <!--Search bar and filter-->
<!--      <vs-row vs-w="12" vs-type="flex" vs-align="center" class="mb-2">-->
<!--        <vs-col vs-lg="5" vs-xs="12" vs-sm="12" vs-md="12" vs-type="flex" vs-align="center" class="p-1">-->
<!--          <input type="text" class="w-100 trading-input-text-search" placeholder="Search transaction "/>-->
<!--        </vs-col>-->
<!--        <vs-col vs-lg="7" vs-xs="12" vs-sm="12" vs-md="12" class="p-1">-->
<!--          <vs-row vs-type="flex" vs-justify="space-around" vs-align="center">-->
<!--            <div v-for="item in transactionDuration" :key="item" class="text-small duration-active">{{item}}</div>-->
<!--          </vs-row>-->
<!--        </vs-col>-->
<!--      </vs-row>-->
<!--{{transactions && transactions}}-->

      <!--Transaction History lists and details-->
      <vs-row vs-w="12">
        <vs-col vs-lg="7" vs-xs="12" vs-sm="12" vs-md="12" class="p-1">

          <!--History List -->
          <div v-if="transactions && transactions.length > 0">

            <div v-for="item in transactions" :key="item.trd_ref_num">
              <!--FOR BUY TRANSACTION-->
              <vs-row v-if="item.tx_type === 'BUY'" class="white-card-md mb-2 w-100 pointer" :class="{'white-card-md-highlighted' : transactionSelected && (transactionSelected.trd_ref_num === item.trd_ref_num)}" vs-type="flex" @click.native="selectTransaction(item)">
                <vs-col vs-type="flex" vs-w="2" vs-justify="center" vs-align="center" class="p-1" style="border-right: 1px solid lightgrey">
                  <div class="trading-flex-column">
                    <div class="text-center fw-700">
                      {{formatDateOnly(item.trd_dt)}}
                    </div>
                    <div class="text-center">
                      {{formatMonthOnly(item.trd_dt)}}
                    </div>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="10" vs-align="center" class="p-2">
                  <vs-row vs-type="flex" vs-justify="space-between" vs-align="center" >
                    <div class="trading-flex">
                    <cryptoicon :symbol="item.end_ccy" size="28" class="mr-1"></cryptoicon>
                      <div class="trading-flex-column">
                        <div class="text-medium fw-600">
                          {{item.tx_type}}
                        </div>
                        <div class="text-small">
                          {{item.end_ccy}}
                        </div>
                      </div>
                    </div>

                    <div class="trading-flex-column">
                      <div class="text-small fw-600 text-success">
                        {{item.end_ccy}}
                        <span v-if="item.end_ccy !== 'USDT'">
                        {{formatCryptoPrice(item.end_amt)}}
                        </span>
                        <span v-else>
                        {{formatFiatPrice(item.end_amt)}}
                        </span>
                      </div>
                      <div class="text-small text-right">
                        {{item.beg_ccy}} {{formatFiatPrice(item.beg_amt)}}
                      </div>
                    </div>

                  </vs-row>
                </vs-col>
              </vs-row>
              <!--FOR SELL TRANSACTION-->
              <vs-row v-else-if="item.tx_type === 'SELL'" class="white-card-md mb-2 w-100 pointer" :class="{'white-card-md-highlighted' : transactionSelected && (transactionSelected.trd_ref_num === item.trd_ref_num)}" vs-type="flex" @click.native="selectTransaction(item)">
                <vs-col vs-type="flex" vs-w="2" vs-justify="center" vs-align="center" class="p-1" style="border-right: 1px solid lightgrey">
                  <div class="trading-flex-column">
                    <div class="text-center fw-700">
                      {{formatDateOnly(item.trd_dt)}}
                    </div>
                    <div class="text-center">
                      {{formatMonthOnly(item.trd_dt)}}
                    </div>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="10" vs-align="center" class="p-2">
                  <vs-row vs-type="flex" vs-justify="space-between" vs-align="center" >
                    <div class="trading-flex">
                      <cryptoicon :symbol="item.beg_ccy" size="28" class="mr-1"></cryptoicon>
                      <div class="trading-flex-column">
                        <div class="text-medium fw-600">
                          {{item.tx_type}}
                        </div>
                        <div class="text-small">
                          {{item.beg_ccy}}
                        </div>
                      </div>
                    </div>

                    <div class="trading-flex-column">
                      <div class="text-small fw-600 text-error">
                        {{item.beg_ccy}}
                        <span v-if="item.beg_ccy !== 'USDT'">
                          {{formatCryptoPrice(item.beg_amt)}}
                        </span>
                        <span v-else>
                            {{formatFiatPrice(item.beg_amt)}}
                        </span>
                      </div>
                      <div class="text-small text-right">
                        {{item.end_ccy}} {{formatFiatPrice(item.end_amt)}}
                      </div>
                    </div>

                  </vs-row>
                </vs-col>
              </vs-row>
              <!--FOR REDEEM TRANSACTION-->
              <vs-row v-else-if="item.tx_type === 'VOUCHER' && item.source === 'grab'" class="white-card-md mb-2 w-100 pointer" :class="{'white-card-md-highlighted' : transactionSelected && (transactionSelected.vcr_ref_num === item.vcr_ref_num)}" vs-type="flex" @click.native="selectTransaction(item)">
                <vs-col vs-type="flex" vs-w="2" vs-justify="center" vs-align="center" class="p-1" style="border-right: 1px solid lightgrey">
                  <div class="trading-flex-column">
                    <div class="text-center fw-700">
                      {{formatDateOnly(item.claim_dt)}}
                    </div>
                    <div class="text-center">
                      {{formatMonthOnly(item.claim_dt)}}
                    </div>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="10" vs-align="center" class="p-2">
                  <vs-row vs-type="flex" vs-justify="space-between" vs-align="center" >
                    <div class="trading-flex">
                      <img src="/assets/trading-assets/grab-rewards-new.svg" alt="rewards-logo" style="max-width:25px" class="mr-1" />
                      <div class="trading-flex-column">
                        <div class="text-medium fw-600">
                          REDEEM
                        </div>
                        <div class="text-small">
                          Voucher
                        </div>
                      </div>
                    </div>

                    <div class="trading-flex-column">
                      <div class="text-small fw-600">
                        {{item.vcr_ref_num}}
                      </div>
                      <div class="text-small text-success text-right">
                        SGD {{formatFiatPrice(item.vcr_amt)}}
                      </div>
                    </div>
                  </vs-row>
                </vs-col>
              </vs-row>
              <!--FOR WITHDRAW TRANSACTION-->
              <vs-row v-else-if="item.tx_type === 'WITHDRAW'" class="white-card-md mb-2 w-100 pointer" :class="{'white-card-md-highlighted' : transactionSelected && (transactionSelected.wdrw_ref_num === item.wdrw_ref_num)}" vs-type="flex" @click.native="selectTransaction(item)">
                <vs-col vs-type="flex" vs-w="2" vs-justify="center" vs-align="center" class="p-1" style="border-right: 1px solid lightgrey">
                  <div class="trading-flex-column">
                    <div class="text-center fw-700">
                      {{formatDateOnly(item.wdrw_dt)}}
                    </div>
                    <div class="text-center">
                      {{formatMonthOnly(item.wdrw_dt)}}
                    </div>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="10" vs-align="center" class="p-2">
                  <vs-row vs-type="flex" vs-justify="space-between" vs-align="center" >
                    <div class="trading-flex">
                      <img src="/assets/trading-assets/bank-icon.svg" alt="bank-logo" class="sm-logo mr-1" />
                      <div class="trading-flex-column">
                        <div class="text-medium fw-600">
                          WITHDRAW
                        </div>
                        <div class="text-small">
                          to {{item.bank_name}}
                        </div>
                      </div>
                    </div>

                    <div class="trading-flex-column">
                      <div class="text-small fw-600">
<!--                        {{item.wdrw_ccy}} {{formatFiatPrice(item.wdrw_amt)}}-->
                      </div>
                      <div class="text-small text-error">
                        {{item.wdrw_ccy}} {{formatFiatPrice(item.wdrw_amt)}}
                      </div>
                    </div>
                  </vs-row>
                </vs-col>
              </vs-row>
              <!--FOR PROMOTION EXTRA CREDIT-->
              <vs-row v-else-if="item.tx_type === 'VOUCHER' && item.source === 'extra-credit-promotion'" class="white-card-md mb-2 w-100 pointer" :class="{'white-card-md-highlighted' : transactionSelected && (transactionSelected.vcr_ref_num === item.vcr_ref_num)}" vs-type="flex" @click.native="selectTransaction(item)">
                <vs-col vs-type="flex" vs-w="2" vs-justify="center" vs-align="center" class="p-1" style="border-right: 1px solid lightgrey">
                  <div class="trading-flex-column">
                    <div class="text-center fw-700">
                      {{formatDateOnly(item.claim_dt)}}
                    </div>
                    <div class="text-center">
                      {{formatMonthOnly(item.claim_dt)}}
                    </div>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-w="10" vs-align="center" class="p-2">
                  <vs-row vs-type="flex" vs-justify="space-between" vs-align="center" >
                    <div class="trading-flex">
                      <img src="/assets/trading-assets/grab-rewards-new.svg" alt="rewards-logo" style="max-width:25px" class="mr-1" />
                      <div class="trading-flex-column">
                        <div class="text-medium fw-600">
                          PROMOTION
                        </div>
                        <div class="text-small">
                          Extra Credit
                        </div>
                      </div>
                    </div>

                    <div class="trading-flex-column">
                      <div class="text-small fw-600">
                        {{item.vcr_ref_num}}
                      </div>
                      <div class="text-small text-success text-right">
                        SGD {{formatFiatPrice(item.vcr_amt)}}
                      </div>
                    </div>
                  </vs-row>
                </vs-col>
              </vs-row>
            </div>
          </div>
          <div v-else class="mb-5 text-center">
            <div>No Transaction History</div>
          </div>

          <vs-row vs-type="flex" vs-justify="center" vs-align="center" class="mt-1 mb-1">

            <arrow-left-icon class="pointer mr-1"
                             @click="selectPreviousTransaction()"
                             :disabled="!prevTransactions"
                             :class="[{'trading-text-lightgrey' : !prevTransactions},{'trading-no-pointer' : !prevTransactions}]"
                            ></arrow-left-icon>
            <arrow-right-icon class="pointer" @click="selectNextTransaction"
                              :disabled="transactions.length <= 0"
                              :class="[{'trading-text-lightgrey' : transactions.length <= 0}, {'trading-no-pointer' : transactions.length <= 0}]">

            </arrow-right-icon>

          </vs-row>


        </vs-col>

        <vs-col vs-lg="5" vs-xs="12" vs-sm="12" vs-md="12" class="p-1 trading-transaction-more-data" v-if="transactionSelected">
          <!--FOR BUY TRANSACTION-->
          <div class="trading-flex-column w-100 text-center white-card-lg mb-5" v-if="transactionSelected && transactionSelected.tx_type === 'BUY'">
            <div class="mt-2">
              <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>
            </div>

            <div class="mb-4 mt-2">
              <div class="mb-1 text-success">You have successfully bought</div>
              <div style="display: flex;align-items: center;justify-content: center">
                <cryptoicon :symbol="transactionSelected.end_ccy" size="20" class="mr-1"></cryptoicon>
                <strong>
                  <span v-if="transactionSelected.end_ccy !== 'USDT'">{{transactionSelected.end_ccy}} {{ formatCryptoPrice(transactionSelected.end_amt)}}
                  </span>
                  <span v-else>{{transactionSelected.end_ccy}} {{formatFiatPrice(transactionSelected.end_amt)}}
                  </span>
                </strong>
              </div>
            </div>

            <div class="trading-border-top-dashed">
              <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">
                <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">
                  <div class="text-small">{{formatDateForHistoryContent(transactionSelected.trd_dt)}}</div>
                </vs-col>
              </vs-row>
            </div>

            <div class="trading-border-top-solid">
              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                  <div class="trading-flex-column">
                    <div class="text-left">
                      <div class="text-xsmall">TRANSACTION REFERENCE</div>
                    </div>
                    <div class="text-left">
                      <div class="text-small">{{transactionSelected.trd_ref_num}}</div>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>

            <div class="trading-border-top-solid">
              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                  <div class="trading-flex-column">
                    <div class="text-left">
                      <div class="text-xsmall">YOU BOUGHT</div>
                    </div>
                    <div class="text-left">
                      <div class="text-small">{{transactionSelected.beg_ccy}} {{formatFiatPrice(transactionSelected.beg_amt)}}</div>
                    </div>
                  </div>
                </vs-col>
<!--                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->

<!--                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;-->
<!--                  <div class="trading-flex-column">-->
<!--                    <div class="text-right">-->
<!--                      <div class="text-small">Payment method</div>-->
<!--                    </div>-->
<!--                    <div class="text-right">-->
<!--                      <div class="text-small">Grab Pay</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </vs-col>-->
              </vs-row>
            </div>

            <div class="trading-border-top-solid">
              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
                <div class="trading-flex-column">
                  <div class="text-left">
                    <div class="text-xsmall">RATE</div>
                  </div>
                  <div class="text-left">
                    <div class="text-small">1 {{transactionSelected.end_ccy}} = {{transactionSelected.beg_ccy}} {{formatFiatPrice(transactionSelected.buy_rt)}}</div>
                  </div>
                </div>
              </vs-row>
            </div>
          </div>

          <!--FOR SELL TRANSACTION-->
          <div class="trading-flex-column w-100 text-center white-card-lg mb-5" v-else-if="transactionSelected && transactionSelected.tx_type === 'SELL'">
            <div class="mt-2">
              <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>
            </div>

            <div class="mb-4 mt-2">
              <div class="mb-1 text-success">You have successfully sold</div>
              <div style="display: flex;align-items: center;justify-content: center">
                <cryptoicon :symbol="transactionSelected.beg_ccy" size="20" class="mr-1"></cryptoicon>
                <strong>
                  <span v-if="transactionSelected.beg_ccy !== 'USDT'">
                     {{transactionSelected.beg_ccy}} {{formatCryptoPrice(transactionSelected.beg_amt)}}
                  </span>
                  <span v-else>
                     {{transactionSelected.beg_ccy}} {{formatFiatPrice(transactionSelected.beg_amt)}}
                  </span>

                </strong>
              </div>
            </div>

            <div class="trading-border-top-dashed">
              <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">
                <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">
                  <div class="text-small">{{formatDateForHistoryContent(transactionSelected.trd_dt)}}</div>
                </vs-col>
              </vs-row>
            </div>

            <div class="trading-border-top-solid">
              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                  <div class="trading-flex-column">
                    <div class="text-left">
                      <div class="text-xsmall">TRANSACTION REFERENCE</div>
                    </div>
                    <div class="text-left">
                      <div class="text-small">{{transactionSelected.trd_ref_num}}</div>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>

            <div class="trading-border-top-solid">
              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                  <div class="trading-flex-column">
                    <div class="text-left">
                      <div class="text-xsmall">YOU SOLD</div>
                    </div>
                    <div class="text-left">
                      <div class="text-small">{{transactionSelected.end_ccy}} {{ formatFiatPrice(transactionSelected.end_amt)}}</div>
                    </div>
                  </div>
                </vs-col>
                <!--                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->

                <!--                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;-->
                <!--                  <div class="trading-flex-column">-->
                <!--                    <div class="text-right">-->
                <!--                      <div class="text-small">Payment method</div>-->
                <!--                    </div>-->
                <!--                    <div class="text-right">-->
                <!--                      <div class="text-small">Grab Pay</div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </vs-col>-->
              </vs-row>
            </div>

            <div class="trading-border-top-solid">
              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
                <div class="trading-flex-column">
                  <div class="text-left">
                    <div class="text-xsmall">RATE</div>
                  </div>
                  <div class="text-left">
                    <div class="text-small">1 {{transactionSelected.beg_ccy}} = {{transactionSelected.end_ccy}} {{formatFiatPrice(transactionSelected.sell_rt)}}</div>
                  </div>
                </div>
              </vs-row>
            </div>
          </div>

          <!--FOR REDEEM TRANSACTION-->
          <div class="trading-flex-column w-100 text-center white-card-lg mb-5" v-else-if="transactionSelected && transactionSelected.tx_type === 'VOUCHER' && transactionSelected.source === 'grab'">
            <div class="mt-2">
              <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>
            </div>

            <div class="mb-4 mt-2">
              <div class="text-success text-center">You have redeemed voucher</div>
              <div class="mb-1 text-success">worth of</div>
              <div style="display: flex;align-items: center;justify-content: center"><strong>SGD {{formatFiatPrice(transactionSelected.vcr_amt)}}</strong></div>
            </div>

            <div class="trading-border-top-dashed">
              <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">
                <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">
                  <div class="text-small">{{formatDateForHistoryContent(transactionSelected.claim_dt)}}</div>
                </vs-col>
              </vs-row>
            </div>

            <div class="trading-border-top-solid">
              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                  <div class="trading-flex-column">
                    <div class="text-left">
                      <div class="text-xsmall">VOUCHER CODE</div>
                    </div>
                    <div class="text-left">
                      <div class="text-small">{{transactionSelected.vcr_ref_num}}</div>
                    </div>
                  </div>
                </vs-col>
                <!--                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->

                <!--                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;-->
                <!--                  <div class="trading-flex-column">-->
                <!--                    <div class="text-right">-->
                <!--                      <div class="text-small">Payment method</div>-->
                <!--                    </div>-->
                <!--                    <div class="text-right">-->
                <!--                      <div class="text-small">Grab Pay</div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </vs-col>-->
              </vs-row>
            </div>

<!--            <div class="trading-border-top-solid">-->
<!--              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--                <div class="trading-flex-column">-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">RATE</div>-->
<!--                  </div>-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">1 {{transactionSelected.beg_ccy}} = {{transactionSelected.end_ccy}} {{formatFiatPrice(transactionSelected.sell_rt)}}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </vs-row>-->
<!--            </div>-->
          </div>

          <!--FOR WITHDRAW TRANSACTION-->
          <div class="trading-flex-column w-100 text-center white-card-lg mb-5" v-else-if="transactionSelected && transactionSelected.tx_type === 'WITHDRAW'">
            <div class="mt-2">
              <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>
            </div>

            <div class="mb-4 mt-2">
              <div class="text-success text-center">You have made a withdraw of</div>
              <div style="display: flex;align-items: center;justify-content: center"><strong>{{transactionSelected.wdrw_ccy}} {{formatFiatPrice(transactionSelected.wdrw_amt)}}</strong></div>
            </div>

            <div class="trading-border-top-dashed">
              <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">
                <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">
                  <div class="text-small">{{formatDateForHistoryContent(transactionSelected.wdrw_dt)}}</div>
                </vs-col>
              </vs-row>
            </div>

            <div class="trading-border-top-solid">
              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                  <div class="trading-flex-column">
                    <div class="text-left">
                      <div class="text-xsmall">WITHDRAW REFERENCE</div>
                    </div>
                    <div class="text-left">
                      <div class="text-small">{{transactionSelected.wdrw_ref_num}}</div>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>

            <div class="trading-border-top-solid">
              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                  <div class="trading-flex-column">
                    <div class="text-left">
                      <div class="text-xsmall">WITHDRAW TO</div>
                    </div>
                    <div class="text-left">
                      <div class="text-small">{{transactionSelected.bank_name}}</div>
                    </div>
                  </div>
                </vs-col>
                <!--                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->

                <!--                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;-->
                <!--                  <div class="trading-flex-column">-->
                <!--                    <div class="text-right">-->
                <!--                      <div class="text-small">Payment method</div>-->
                <!--                    </div>-->
                <!--                    <div class="text-right">-->
                <!--                      <div class="text-small">Grab Pay</div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </vs-col>-->
              </vs-row>
            </div>

                        <div class="trading-border-top-solid">
                          <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
                            <div class="trading-flex-column">
                              <div class="text-left">
                                <div class="text-xsmall">BANK ACCOUNT</div>
                              </div>
                              <div class="text-left">
                                <div class="text-small">{{transactionSelected.bank_acct_num}}</div>
                              </div>
                            </div>
                          </vs-row>
                        </div>
          </div>

          <!--FOR PROMOTION EXTRA CREDIT-->
          <div class="trading-flex-column w-100 text-center white-card-lg mb-5" v-else-if="transactionSelected && transactionSelected.tx_type === 'VOUCHER' && transactionSelected.source === 'extra-credit-promotion'">
            <div class="mt-2">
              <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>
            </div>

            <div class="mb-4 mt-2">
              <div class="text-success text-center">You have received extra credit(s) in your voucher balance</div>
              <div class="mb-1 text-success">(promotion)</div>
              <div style="display: flex;align-items: center;justify-content: center"><strong>SGD {{formatFiatPrice(transactionSelected.vcr_amt)}}</strong></div>
            </div>

            <div class="trading-border-top-dashed">
              <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">
                <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">
                  <div class="text-small">{{formatDateForHistoryContent(transactionSelected.claim_dt)}}</div>
                </vs-col>
              </vs-row>
            </div>

            <div class="trading-border-top-solid">
              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                  <div class="trading-flex-column">
                    <div class="text-left">
                      <div class="text-xsmall">VOUCHER CODE</div>
                    </div>
                    <div class="text-left">
                      <div class="text-small">{{transactionSelected.vcr_ref_num}}</div>
                    </div>
                  </div>
                </vs-col>
                <!--                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->

                <!--                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;-->
                <!--                  <div class="trading-flex-column">-->
                <!--                    <div class="text-right">-->
                <!--                      <div class="text-small">Payment method</div>-->
                <!--                    </div>-->
                <!--                    <div class="text-right">-->
                <!--                      <div class="text-small">Grab Pay</div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </vs-col>-->
              </vs-row>
            </div>

            <!--            <div class="trading-border-top-solid">-->
            <!--              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
            <!--                <div class="trading-flex-column">-->
            <!--                  <div class="text-left">-->
            <!--                    <div class="text-small">RATE</div>-->
            <!--                  </div>-->
            <!--                  <div class="text-left">-->
            <!--                    <div class="text-small">1 {{transactionSelected.beg_ccy}} = {{transactionSelected.end_ccy}} {{formatFiatPrice(transactionSelected.sell_rt)}}</div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </vs-row>-->
            <!--            </div>-->
          </div>

        </vs-col>
      </vs-row>
    </div>

<!--    &lt;!&ndash;    POPUP IN SMALL SCREEN&ndash;&gt;-->
<!--    <vs-popup fullscreen title="Transaction Details" :active.sync="popupActive" style="background: #F2F5F7 none repeat scroll 0% 0% !important;">-->
<!--      <div class="sm-trading-content">-->
<!--        &lt;!&ndash;FOR BUY TRANSACTION&ndash;&gt;-->
<!--        <div class="trading-flex-column w-100 text-center white-card-lg mb-5 card-border-popup" v-if="transactionSelected && transactionSelected.tx_type === 'BUY'">-->
<!--          <div class="mt-2">-->
<!--            <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>-->
<!--          </div>-->

<!--          <div class="mb-4 mt-2">-->
<!--            <div class="mb-1 text-success">You have successfully bought</div>-->
<!--            <div style="display: flex;align-items: center;justify-content: center">-->
<!--              <cryptoicon :symbol="transactionSelected.end_ccy" size="20" class="mr-1"></cryptoicon>-->
<!--              <strong>-->
<!--                  <span v-if="transactionSelected.end_ccy !== 'USDT'">{{transactionSelected.end_ccy}} {{ formatCryptoPrice(transactionSelected.end_amt)}}-->
<!--                  </span>-->
<!--                <span v-else>{{transactionSelected.end_ccy}} {{formatFiatPrice(transactionSelected.end_amt)}}-->
<!--                  </span>-->
<!--              </strong>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="trading-border-top-dashed">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="text-small">{{ formatDateForHistoryContent(transactionSelected.trd_dt)}}</div>-->
<!--              </vs-col>-->
<!--            </vs-row>-->
<!--          </div>-->

<!--          <div class="trading-border-top-solid">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="trading-flex-column">-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">TRANSACTION REFERENCE</div>-->
<!--                  </div>-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">{{transactionSelected.trd_ref_num}}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </vs-col>-->
<!--            </vs-row>-->
<!--          </div>-->

<!--          <div class="trading-border-top-solid">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="trading-flex-column">-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">YOU BOUGHT</div>-->
<!--                  </div>-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">{{transactionSelected.beg_ccy}} {{formatFiatPrice(transactionSelected.beg_amt)}}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </vs-col>-->
<!--              &lt;!&ndash;                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">&ndash;&gt;-->

<!--              &lt;!&ndash;                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;&ndash;&gt;-->
<!--              &lt;!&ndash;                  <div class="trading-flex-column">&ndash;&gt;-->
<!--              &lt;!&ndash;                    <div class="text-right">&ndash;&gt;-->
<!--              &lt;!&ndash;                      <div class="text-small">Payment method</div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    <div class="text-right">&ndash;&gt;-->
<!--              &lt;!&ndash;                      <div class="text-small">Grab Pay</div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                </vs-col>&ndash;&gt;-->
<!--            </vs-row>-->
<!--          </div>-->

<!--          <div class="trading-border-top-solid">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <div class="trading-flex-column">-->
<!--                <div class="text-left">-->
<!--                  <div class="text-small">RATE</div>-->
<!--                </div>-->
<!--                <div class="text-left">-->
<!--                  <div class="text-small">1 {{transactionSelected.end_ccy}} = {{transactionSelected.beg_ccy}} {{formatFiatPrice(transactionSelected.buy_rt)}}</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </vs-row>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;FOR SELL TRANSACTION&ndash;&gt;-->
<!--        <div class="trading-flex-column w-100 text-center white-card-lg mb-5 card-border-popup" v-else-if="transactionSelected && transactionSelected.tx_type === 'SELL'">-->
<!--          <div class="mt-2">-->
<!--            <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>-->
<!--          </div>-->

<!--          <div class="mb-4 mt-2">-->
<!--            <div class="mb-1 text-success">You have successfully sold</div>-->
<!--            <div style="display: flex;align-items: center;justify-content: center">-->
<!--              <cryptoicon :symbol="transactionSelected.beg_ccy" size="20" class="mr-1"></cryptoicon>-->
<!--              <strong>{{transactionSelected.beg_ccy}} {{formatCryptoPrice(transactionSelected.beg_amt)}}</strong></div>-->
<!--          </div>-->

<!--          <div class="trading-border-top-dashed">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="text-small">{{formatDateForHistoryContent(transactionSelected.trd_dt)}}</div>-->
<!--              </vs-col>-->
<!--            </vs-row>-->
<!--          </div>-->

<!--          <div class="trading-border-top-solid">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="trading-flex-column">-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">TRANSACTION REFERENCE</div>-->
<!--                  </div>-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">{{transactionSelected.trd_ref_num}}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </vs-col>-->
<!--            </vs-row>-->
<!--          </div>-->

<!--          <div class="trading-border-top-solid">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="trading-flex-column">-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">YOU SOLD</div>-->
<!--                  </div>-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">{{transactionSelected.end_ccy}} {{ formatFiatPrice(transactionSelected.end_amt)}}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </vs-col>-->
<!--              &lt;!&ndash;                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">&ndash;&gt;-->

<!--              &lt;!&ndash;                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;&ndash;&gt;-->
<!--              &lt;!&ndash;                  <div class="trading-flex-column">&ndash;&gt;-->
<!--              &lt;!&ndash;                    <div class="text-right">&ndash;&gt;-->
<!--              &lt;!&ndash;                      <div class="text-small">Payment method</div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    <div class="text-right">&ndash;&gt;-->
<!--              &lt;!&ndash;                      <div class="text-small">Grab Pay</div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                </vs-col>&ndash;&gt;-->
<!--            </vs-row>-->
<!--          </div>-->

<!--          <div class="trading-border-top-solid">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <div class="trading-flex-column">-->
<!--                <div class="text-left">-->
<!--                  <div class="text-small">RATE</div>-->
<!--                </div>-->
<!--                <div class="text-left">-->
<!--                  <div class="text-small">1 {{transactionSelected.beg_ccy}} = {{transactionSelected.end_ccy}} {{formatFiatPrice(transactionSelected.sell_rt)}}</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </vs-row>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;FOR REDEEM TRANSACTION&ndash;&gt;-->
<!--        <div class="trading-flex-column w-100 text-center white-card-lg mb-5 card-border-popup" v-else-if="transactionSelected && transactionSelected.tx_type === 'VOUCHER'">-->
<!--          <div class="mt-2">-->
<!--            <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>-->
<!--          </div>-->

<!--          <div class="mb-4 mt-2">-->
<!--            <div class="text-success text-center">You have redeemed voucher</div>-->
<!--            <div class="mb-1 text-success">worth of</div>-->
<!--            <div style="display: flex;align-items: center;justify-content: center"><strong>SGD {{formatFiatPrice(transactionSelected.vcr_amt)}}</strong></div>-->
<!--          </div>-->

<!--          <div class="trading-border-top-dashed">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="text-small">{{ formatDateForHistoryContent(transactionSelected.claim_dt)}}</div>-->
<!--              </vs-col>-->
<!--            </vs-row>-->
<!--          </div>-->

<!--          <div class="trading-border-top-solid">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="trading-flex-column">-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">VOUCHER CODE</div>-->
<!--                  </div>-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">{{transactionSelected.vcr_ref_num}}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </vs-col>-->
<!--              &lt;!&ndash;                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">&ndash;&gt;-->

<!--              &lt;!&ndash;                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;&ndash;&gt;-->
<!--              &lt;!&ndash;                  <div class="trading-flex-column">&ndash;&gt;-->
<!--              &lt;!&ndash;                    <div class="text-right">&ndash;&gt;-->
<!--              &lt;!&ndash;                      <div class="text-small">Payment method</div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    <div class="text-right">&ndash;&gt;-->
<!--              &lt;!&ndash;                      <div class="text-small">Grab Pay</div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                </vs-col>&ndash;&gt;-->
<!--            </vs-row>-->
<!--          </div>-->

<!--          &lt;!&ndash;            <div class="trading-border-top-solid">&ndash;&gt;-->
<!--          &lt;!&ndash;              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">&ndash;&gt;-->
<!--          &lt;!&ndash;                <div class="trading-flex-column">&ndash;&gt;-->
<!--          &lt;!&ndash;                  <div class="text-left">&ndash;&gt;-->
<!--          &lt;!&ndash;                    <div class="text-small">RATE</div>&ndash;&gt;-->
<!--          &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--          &lt;!&ndash;                  <div class="text-left">&ndash;&gt;-->
<!--          &lt;!&ndash;                    <div class="text-small">1 {{transactionSelected.beg_ccy}} = {{transactionSelected.end_ccy}} {{formatFiatPrice(transactionSelected.sell_rt)}}</div>&ndash;&gt;-->
<!--          &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--          &lt;!&ndash;                </div>&ndash;&gt;-->
<!--          &lt;!&ndash;              </vs-row>&ndash;&gt;-->
<!--          &lt;!&ndash;            </div>&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;FOR WITHDRAW TRANSACTION&ndash;&gt;-->
<!--        <div class="trading-flex-column w-100 text-center white-card-lg mb-5 card-border-popup" v-else-if="transactionSelected && transactionSelected.tx_type === 'WITHDRAW'">-->
<!--          <div class="mt-2">-->
<!--            <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>-->
<!--          </div>-->

<!--          <div class="mb-4 mt-2">-->
<!--            <div class="text-success text-center">You have made a withdraw of</div>-->
<!--            <div style="display: flex;align-items: center;justify-content: center"><strong>{{transactionSelected.wdrw_ccy}} {{formatFiatPrice(transactionSelected.wdrw_amt)}}</strong></div>-->
<!--          </div>-->

<!--          <div class="trading-border-top-dashed">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="text-small">{{formatDateForHistoryContent(transactionSelected.wdrw_dt)}}</div>-->
<!--              </vs-col>-->
<!--            </vs-row>-->
<!--          </div>-->

<!--          <div class="trading-border-top-solid">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="trading-flex-column">-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">WITHDRAW REFERENCE</div>-->
<!--                  </div>-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">{{transactionSelected.wdrw_ref_num}}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </vs-col>-->
<!--            </vs-row>-->
<!--          </div>-->

<!--          <div class="trading-border-top-solid">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">-->
<!--                <div class="trading-flex-column">-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">WITHDRAW TO</div>-->
<!--                  </div>-->
<!--                  <div class="text-left">-->
<!--                    <div class="text-small">{{transactionSelected.bank_name}}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </vs-col>-->
<!--              &lt;!&ndash;                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">&ndash;&gt;-->

<!--              &lt;!&ndash;                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;&ndash;&gt;-->
<!--              &lt;!&ndash;                  <div class="trading-flex-column">&ndash;&gt;-->
<!--              &lt;!&ndash;                    <div class="text-right">&ndash;&gt;-->
<!--              &lt;!&ndash;                      <div class="text-small">Payment method</div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    <div class="text-right">&ndash;&gt;-->
<!--              &lt;!&ndash;                      <div class="text-small">Grab Pay</div>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                </vs-col>&ndash;&gt;-->
<!--            </vs-row>-->
<!--          </div>-->

<!--          <div class="trading-border-top-solid">-->
<!--            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
<!--              <div class="trading-flex-column">-->
<!--                <div class="text-left">-->
<!--                  <div class="text-small">BANK ACCOUNT</div>-->
<!--                </div>-->
<!--                <div class="text-left">-->
<!--                  <div class="text-small">{{transactionSelected.bank_acct_num}}</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </vs-row>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </vs-popup>-->

    <!--    POPUP IN SMALL SCREEN (NOT USING VUESAX POPUP) -->
    <div class="trading-popup-crypto-content-container" v-if="popupActive">
      <div>

        <vs-row vs-type="flex" vs-justify="space-between" vs-align="center" class="mb-2" v-if="transactionSelected">
          <div class="pointer" @click="closePopUp()">
            <arrow-left-icon size="1.5x"></arrow-left-icon>
          </div>
          <img
              src="/assets/trading-assets/TransCrypt-logo-symbol.png" alt="TransCrypt-logo" class="md-logo"/>

        </vs-row>
        <!--FOR BUY TRANSACTION-->
        <div class="trading-flex-column w-100 text-center white-card-lg mb-5 card-border-popup" v-if="transactionSelected && transactionSelected.tx_type === 'BUY'">
          <div class="mt-2">
            <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>
          </div>

          <div class="mb-4 mt-2">
            <div class="mb-1 text-success">You have successfully bought</div>
            <div style="display: flex;align-items: center;justify-content: center">
              <cryptoicon :symbol="transactionSelected.end_ccy" size="20" class="mr-1"></cryptoicon>
              <strong>
                  <span v-if="transactionSelected.end_ccy !== 'USDT'">{{transactionSelected.end_ccy}} {{ formatCryptoPrice(transactionSelected.end_amt)}}
                  </span>
                <span v-else>{{transactionSelected.end_ccy}} {{formatFiatPrice(transactionSelected.end_amt)}}
                  </span>
              </strong>
            </div>
          </div>

          <div class="trading-border-top-dashed">
            <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">
              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">
                <div class="text-small">{{ formatDateForHistoryContent(transactionSelected.trd_dt)}}</div>
              </vs-col>
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                <div class="trading-flex-column">
                  <div class="text-left">
                    <div class="text-small">TRANSACTION REFERENCE</div>
                  </div>
                  <div class="text-left">
                    <div class="text-small">{{transactionSelected.trd_ref_num}}</div>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                <div class="trading-flex-column">
                  <div class="text-left">
                    <div class="text-small">YOU BOUGHT</div>
                  </div>
                  <div class="text-left">
                    <div class="text-small">{{transactionSelected.beg_ccy}} {{formatFiatPrice(transactionSelected.beg_amt)}}</div>
                  </div>
                </div>
              </vs-col>
              <!--                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->

              <!--                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;-->
              <!--                  <div class="trading-flex-column">-->
              <!--                    <div class="text-right">-->
              <!--                      <div class="text-small">Payment method</div>-->
              <!--                    </div>-->
              <!--                    <div class="text-right">-->
              <!--                      <div class="text-small">Grab Pay</div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </vs-col>-->
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <div class="trading-flex-column">
                <div class="text-left">
                  <div class="text-small">RATE</div>
                </div>
                <div class="text-left">
                  <div class="text-small">1 {{transactionSelected.end_ccy}} = {{transactionSelected.beg_ccy}} {{formatFiatPrice(transactionSelected.buy_rt)}}</div>
                </div>
              </div>
            </vs-row>
          </div>
        </div>
        <!--FOR SELL TRANSACTION-->
        <div class="trading-flex-column w-100 text-center white-card-lg mb-5 card-border-popup" v-else-if="transactionSelected && transactionSelected.tx_type === 'SELL'">
          <div class="mt-2">
            <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>
          </div>

          <div class="mb-4 mt-2">
            <div class="mb-1 text-success">You have successfully sold</div>
            <div style="display: flex;align-items: center;justify-content: center">
              <cryptoicon :symbol="transactionSelected.beg_ccy" size="20" class="mr-1"></cryptoicon>
              <strong>{{transactionSelected.beg_ccy}} {{formatCryptoPrice(transactionSelected.beg_amt)}}</strong></div>
          </div>

          <div class="trading-border-top-dashed">
            <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">
              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">
                <div class="text-small">{{formatDateForHistoryContent(transactionSelected.trd_dt)}}</div>
              </vs-col>
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                <div class="trading-flex-column">
                  <div class="text-left">
                    <div class="text-small">TRANSACTION REFERENCE</div>
                  </div>
                  <div class="text-left">
                    <div class="text-small">{{transactionSelected.trd_ref_num}}</div>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                <div class="trading-flex-column">
                  <div class="text-left">
                    <div class="text-small">YOU SOLD</div>
                  </div>
                  <div class="text-left">
                    <div class="text-small">{{transactionSelected.end_ccy}} {{ formatFiatPrice(transactionSelected.end_amt)}}</div>
                  </div>
                </div>
              </vs-col>
              <!--                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->

              <!--                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;-->
              <!--                  <div class="trading-flex-column">-->
              <!--                    <div class="text-right">-->
              <!--                      <div class="text-small">Payment method</div>-->
              <!--                    </div>-->
              <!--                    <div class="text-right">-->
              <!--                      <div class="text-small">Grab Pay</div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </vs-col>-->
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <div class="trading-flex-column">
                <div class="text-left">
                  <div class="text-small">RATE</div>
                </div>
                <div class="text-left">
                  <div class="text-small">1 {{transactionSelected.beg_ccy}} = {{transactionSelected.end_ccy}} {{formatFiatPrice(transactionSelected.sell_rt)}}</div>
                </div>
              </div>
            </vs-row>
          </div>
        </div>
        <!--FOR REDEEM TRANSACTION-->
        <div class="trading-flex-column w-100 text-center white-card-lg mb-5 card-border-popup" v-else-if="transactionSelected && transactionSelected.tx_type === 'VOUCHER'">
          <div class="mt-2">
            <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>
          </div>

          <div class="mb-4 mt-2">
            <div class="text-success text-center">You have redeemed voucher</div>
            <div class="mb-1 text-success">worth of</div>
            <div style="display: flex;align-items: center;justify-content: center"><strong>SGD {{formatFiatPrice(transactionSelected.vcr_amt)}}</strong></div>
          </div>

          <div class="trading-border-top-dashed">
            <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">
              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">
                <div class="text-small">{{ formatDateForHistoryContent(transactionSelected.claim_dt)}}</div>
              </vs-col>
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                <div class="trading-flex-column">
                  <div class="text-left">
                    <div class="text-small">VOUCHER CODE</div>
                  </div>
                  <div class="text-left">
                    <div class="text-small">{{transactionSelected.vcr_ref_num}}</div>
                  </div>
                </div>
              </vs-col>
              <!--                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->

              <!--                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;-->
              <!--                  <div class="trading-flex-column">-->
              <!--                    <div class="text-right">-->
              <!--                      <div class="text-small">Payment method</div>-->
              <!--                    </div>-->
              <!--                    <div class="text-right">-->
              <!--                      <div class="text-small">Grab Pay</div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </vs-col>-->
            </vs-row>
          </div>

          <!--            <div class="trading-border-top-solid">-->
          <!--              <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">-->
          <!--                <div class="trading-flex-column">-->
          <!--                  <div class="text-left">-->
          <!--                    <div class="text-small">RATE</div>-->
          <!--                  </div>-->
          <!--                  <div class="text-left">-->
          <!--                    <div class="text-small">1 {{transactionSelected.beg_ccy}} = {{transactionSelected.end_ccy}} {{formatFiatPrice(transactionSelected.sell_rt)}}</div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </vs-row>-->
          <!--            </div>-->
        </div>
        <!--FOR WITHDRAW TRANSACTION-->
        <div class="trading-flex-column w-100 text-center white-card-lg mb-5 card-border-popup" v-else-if="transactionSelected && transactionSelected.tx_type === 'WITHDRAW'">
          <div class="mt-2">
            <img src="/assets/trading-assets/tick-icon.svg" alt="bank-logo" class="lg-logo"/>
          </div>

          <div class="mb-4 mt-2">
            <div class="text-success text-center">You have made a withdraw of</div>
            <div style="display: flex;align-items: center;justify-content: center"><strong>{{transactionSelected.wdrw_ccy}} {{formatFiatPrice(transactionSelected.wdrw_amt)}}</strong></div>
          </div>

          <div class="trading-border-top-dashed">
            <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" class="p-2">
              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start">
                <div class="text-small">{{formatDateForHistoryContent(transactionSelected.wdrw_dt)}}</div>
              </vs-col>
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                <div class="trading-flex-column">
                  <div class="text-left">
                    <div class="text-small">WITHDRAW REFERENCE</div>
                  </div>
                  <div class="text-left">
                    <div class="text-small">{{transactionSelected.wdrw_ref_num}}</div>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
                <div class="trading-flex-column">
                  <div class="text-left">
                    <div class="text-small">WITHDRAW TO</div>
                  </div>
                  <div class="text-left">
                    <div class="text-small">{{transactionSelected.bank_name}}</div>
                  </div>
                </div>
              </vs-col>
              <!--                <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">-->

              <!--                  &lt;!&ndash;TODO show the payment method if it is a 'buy'&ndash;&gt;-->
              <!--                  <div class="trading-flex-column">-->
              <!--                    <div class="text-right">-->
              <!--                      <div class="text-small">Payment method</div>-->
              <!--                    </div>-->
              <!--                    <div class="text-right">-->
              <!--                      <div class="text-small">Grab Pay</div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </vs-col>-->
            </vs-row>
          </div>

          <div class="trading-border-top-solid">
            <vs-row vs-type="flex" vs-w="12" vs-justify="flex-start" vs-align="center" class="p-2">
              <div class="trading-flex-column">
                <div class="text-left">
                  <div class="text-small">BANK ACCOUNT</div>
                </div>
                <div class="text-left">
                  <div class="text-small">{{transactionSelected.bank_acct_num}}</div>
                </div>
              </div>
            </vs-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import BigNumber from 'bignumber.js';
import {SearchIcon, ChevronDownIcon, ArrowLeftIcon, ArrowRightIcon, CheckIcon} from 'vue-feather-icons'
// import { countries } from 'country-flag-icons';
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import CountryFlag from 'vue-country-flag'

import {debounce} from '../../helpers/helpers'




export default {
  name: 'trading-history-page',
  components: {ArrowRightIcon, ArrowLeftIcon},
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

      historyList: [
        {
          type : 'buy',
          cryptoCode: 'BTC',
          cryptoName: 'Bitcoin',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
        },
        {
          type : 'sell',
          cryptoCode: 'ETH',
          cryptoName: 'Ethereum',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
        },
        {
          type : 'buy',
          cryptoCode: 'USDT',
          cryptoName: 'Tether',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
        },
        {
          type : 'buy',
          cryptoCode: 'LTC',
          cryptoName: 'Litecoin',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
        },
        {
          type:'withdraw',
          cryptoCode: 'XRP',
          cryptoName: 'Ripple',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
          bankName : 'bank xxx'
        },
        {
          cryptoCode: 'ADA',
          cryptoName: 'Cardano',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
        }
      ],
      popupActive: false,

      windowInnerWidth: null,
      transactionDuration : ['1 day', '1 week', '1 month', '1 year'],
      transactionSelected : null,

      debugConsole : false


    }
  },
  watch: {},
  computed: {
    ...mapGetters(["userTransactionHistoryGetter"]),

    transactions(){
      let userTransactionHistory = this.userTransactionHistoryGetter && this.userTransactionHistoryGetter.message && this.userTransactionHistoryGetter.message.data;

      return userTransactionHistory;
    },
    nextTransactions(){
      //return the api needed for the next transactions
      let nextTransactions = this.userTransactionHistoryGetter && this.userTransactionHistoryGetter.message && this.userTransactionHistoryGetter.message.meta.next;
      return nextTransactions;
    },
    prevTransactions(){
      //return the api needed for the prev transactions
      let prevTransactions = this.userTransactionHistoryGetter && this.userTransactionHistoryGetter.message && this.userTransactionHistoryGetter.message.meta.prev;
      return prevTransactions;
    }
  },
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  created() {
    window.addEventListener("resize", () => {this.windowInnerWidthHandler()});
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {this.windowInnerWidthHandler()} );
  },
  mounted() {



    this.windowInnerWidth = window.innerWidth;

    //get transaction history
    this.getTransactionHistoryAction().then(res => {
     if(this.debugConsole) console.log("Get Transaction History in history page", res.data)

      //initialize the selectedTransaction
      this.transactionSelected = this.transactions[0];
      if(this.debugConsole)  console.log("This transaction Selected", this.transactionSelected);

    }).catch(err => {
      console.log("Error in getting txn history in history page", err);
    })





  },
  methods : {
    ...mapActions(["getTransactionHistoryAction"]),

    windowInnerWidthHandler() {
      this.windowInnerWidth = window.innerWidth;

      //if window width > 900 px, disable the popup
      if(this.windowInnerWidth > 900)
      {
        this.popupActive = false;
      }
    },

    selectTransaction(item){
      if(this.debugConsole)  console.log("Transaction Selected", item);
      this.transactionSelected = item;

      if(this.windowInnerWidth <= 900)
      {
        this.popupActive = true;
      }else {
        this.popupActive = false;
      }

    },
    selectNextTransaction(){
      if(this.nextTransactions)
      {
        let getQueryString = this.nextTransactions.split('?')[1];
        if(this.debugConsole)    console.log("Get Query String for next transactions", getQueryString);

        let payload = {};
        payload.queryString = getQueryString

        this.getTransactionHistoryAction(payload).then(res => {
          if(this.debugConsole)      console.log("Getting transaction history action (next)", res);

        }).catch(err => {
          console.log(" error in getting transaction history action (next)", err);
        })


      }
    },
    selectPreviousTransaction(){
      if(this.prevTransactions)
      {
        let getQueryString = this.prevTransactions.split('?')[1];
        if(this.debugConsole)    console.log("Get Query String for prev transactions", getQueryString)

        let payload = {};
        payload.queryString = getQueryString;

        this.getTransactionHistoryAction(payload).then(res => {
          if(this.debugConsole)      console.log("Getting transaction history action (prev)", res);

        }).catch(err => {
          console.log("error in getting transaction history action (prev)", err);
        })


      }
    },
    closePopUp()
    {
      this.popupActive = false;
    },

  }

}

</script>
<style scoped>


</style>